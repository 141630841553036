import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Test from "../components/test"

const Testi = () => (
  <Layout>
    <Seo title="Testaa vahvuutesi" />
    <Test id="q" />
  </Layout>
)

export default Testi
