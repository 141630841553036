const questions = [
  {
    questionText: "1. Mikä seuraavista tuntuu sopivimmalta käteesi?",
    answerOptions: [
      { answerText: "Sideharso", value: "a" },
      { answerText: "Kynä", value: "b" },
      { answerText: "Kirja", value: "c" },
      { answerText: "Kartta", value: "d" },
      { answerText: "Vasara", value: "e" },
      { answerText: "Katupora", value: "f" },
    ],
  },
  {
    questionText: "2. Ystäväsi loukkaa metsäretkellä jalkansa. Miten toimit? ",
    answerOptions: [
      { answerText: "Annan välittömästi ensiavun", value: "a" },
      { answerText: "Rauhoittelen ja lohdutan ystävää", value: "b" },
      {
        answerText: "Ei hätää – tiedän nopeimman oikopolun avun luo ",
        value: "c",
      },
      { answerText: "Selvitän sijaintimme ja miten tulee toimia", value: "d" },
      {
        answerText: "Veistelen puusta lastan ja punon pajusta kiristyssiteen",
        value: "e",
      },
      {
        answerText: "Kannan kaverin palomiesotteella kotiin asti ",
        value: "f",
      },
    ],
  },
  {
    questionText: "3. Mitä katsot mieluiten tv:stä?",
    answerOptions: [
      { answerText: "Ohjelmaa tosielämän ystävistä", value: "a" },
      {
        answerText: "Draamaelokuvaa kaiken voittavasta rakkaudesta",
        value: "b",
      },
      {
        answerText: "Dokumenttia shakkimestarista",
        value: "c",
      },
      { answerText: "Tietokilpailuja ja visailuohjelmia", value: "d" },
      {
        answerText: "Strömsötä ja Remontti-Reiskaa ",
        value: "e",
      },
      {
        answerText: "Maailman vahvin -kisojen huippuhetkiä",
        value: "f",
      },
    ],
  },
  {
    questionText: "4. Mitä harrastat mieluiten?",
    answerOptions: [
      { answerText: "Lautapelien pelaamista kaveriporukalla", value: "a" },
      {
        answerText: "Runojen kirjoittamista ja akvarellimaalausta",
        value: "b",
      },
      {
        answerText: "Joogaa ja mindfulnessia",
        value: "c",
      },
      { answerText: "Ristisanatehtäviä ja pulmapalapelejä", value: "d" },
      {
        answerText: "Kaikenlaisia käsitöitä ja nikkarointia",
        value: "e",
      },
      {
        answerText: "Treenaan Havaijin Ironman-kisoja varten",
        value: "f",
      },
    ],
  },
  {
    questionText: "5. Mitä kuuluu kuulokkeistasi?",
    answerOptions: [
      { answerText: "Mitä ikinä ystäväni haluavatkaan kuunnella", value: "a" },
      {
        answerText: "Herkkiä balladeja, joista ei tunnetta puutu",
        value: "b",
      },
      {
        answerText: "Keskittymistä parantavia ääniä, kuten sateen ropinaa",
        value: "c",
      },
      {
        answerText: "Podcast johtamisen ja päätöksenteon psykologiasta",
        value: "d",
      },
      {
        answerText: "Tee se itse -ohjevideo leikkimökin rakentamisesta",
        value: "e",
      },
      { answerText: "Mitä tahansa heviä, mieluiten bläkkistä", value: "f" },
    ],
  },
]

export default questions
