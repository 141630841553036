import React, { useState } from "react"
import Questions from "./questions"
import { navigate } from "gatsby-link"

const Test = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [currentAnswer, setCurrentAnswer] = useState("")
  const [showScore, setShowScore] = useState(false)
  const [showQuiz, setShowQuiz] = useState(true)
  const [score, setScore] = useState([])
  const questions = Questions

  const getResult = score => {
    switch (score) {
      case "a":
        navigate("/medium#1")
        break
      case "b":
        navigate("/kharisma#1")
        break
      case "c":
        navigate("/magnifika#1")
        break
      case "d":
        navigate("/jubileum#1")
        break
      case "e":
        navigate("/crescendo#1")
        break
      case "f":
        navigate("/inferno#1")
        break
      default:
        break
    }
  }

  const handleAnswerButtonClick = answerOption => {
    setCurrentAnswer("")
    setScore(score => [...score, answerOption])
    const nextQuestion = currentQuestion + 1
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      setShowScore(true)
      setShowQuiz(false)
    }
  }
  function mode(arr) {
    return arr
      .sort(
        (a, b) =>
          arr.filter(v => v === a).length - arr.filter(v => v === b).length
      )
      .pop()
  }

  return (
    <div>
      <div className="test">
        {showQuiz && (
          <>
            <h3 className="question-text">
              {questions[currentQuestion].questionText}
            </h3>
            <div className="answer-section">
              {questions[currentQuestion].answerOptions.map(
                (answerOption, index) => (
                  <button
                    className={
                      answerOption.value === currentAnswer ? "active" : ""
                    }
                    key={index}
                    onClick={() => setCurrentAnswer(answerOption.value)}
                  >
                    {answerOption.answerText}
                  </button>
                )
              )}
            </div>
            <button
              onClick={() =>
                currentAnswer !== "" && handleAnswerButtonClick(currentAnswer)
              }
            >
              Seuraava
            </button>
          </>
        )}
        <div className="score">
          {showScore ? (
            <div className="score-section">
              {console.log("score:", score)}
              {console.log("mode:", mode(score))}
              {getResult(mode(score))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Test
